import { inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthState, authFeature } from './store/auth.reducer';

/**
 * This is a guard that checks if the user is authenticated
 * and make sure that the user is redirected to the login page.
 */
export const authGuard: CanActivateFn = async () => {
  const authStore = inject<Store<AuthState>>(Store);
  const isAuthenticated = toSignal(
    authStore.select(authFeature.selectIsAuthenticated)
  );
  const router = inject(Router);

  if (!isAuthenticated()) {
    await router.navigate(['/login']).then(() => false);
    return false;
  }

  return true;
};
