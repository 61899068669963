import { Injectable } from '@angular/core';
import { Socket, SocketIoConfig } from 'ngx-socket-io';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ChatsSocket extends Socket {
  public static ChatMessageAnswered = 'chat.message.answered';
  public static Error = 'error';

  constructor() {
    super({
      url: `${environment.apiBaseUrl}/chats`,
      options: {
        reconnection: true,
        closeOnBeforeunload: true,
        withCredentials: true,
        protocols: ['polling', 'websocket'],
        upgrade: true,
      },
    } satisfies SocketIoConfig);
  }
}
