import { NgClass, NgIf } from '@angular/common';
import { Component, OnInit, inject, input, output } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromSeriousSystem from '@serious-system';
import * as fromChat from './index';

@Component({
  selector: 'squadbox-chats-mobile',
  standalone: true,
  imports: [
    fromSeriousSystem.UseUIBasicIconDirective,
    fromSeriousSystem.UseArrowIconDirective,
    fromSeriousSystem.IconButtonDirective,
    NgIf,
    NgClass,
  ],
  template: `
    <div
      class="grid grid-rows-[auto_1fr] h-svh w-screen overflow-hidden"
      [class.bg-gradient-b-diagonal]="isChatsContainerOpened()"
    >
      <!-- TopBar Home -->
      @if (isChatsContainerOpened()) {
      <div
        class="flex flex-row justify-between items-center py-2 px-4 text-neutral-50 sticky top-0 z-10"
      >
        <div class="flex flex-row items-center gap-4 dark">
          <button
            sdIconButton
            variant="icon"
            color="neutral"
            size="md"
            (click)="toggleSideContainer()"
          >
            <svg sdUseUIBasicIcon="menu"></svg>
          </button>

          <span class="typo-subheader font-semibold"> Home </span>
        </div>
      </div>

      <!-- FAB new-convo -->
      <button
        sdIconButton
        variant="filled"
        color="primary"
        size="lg"
        class="fixed bottom-4 right-4 z-20 shadow-md"
        (click)="toggleChatsContainer()"
        (click)="newConvoClicked.emit()"
      >
        <svg sdUseUIBasicIcon="new-convo"></svg>
      </button>

      <!-- TopBar Chat -->
      } @else {
      <div
        class="grid grid-cols-[min-content_auto_min-content] gap-4 justify-between items-center p-2 z-10 w-full border-b border-neutral-200"
      >
        <button
          sdIconButton
          variant="icon"
          color="neutral"
          size="md"
          (click)="toggleChatsContainer()"
        >
          <svg sdUseArrowIcon="chevron-left"></svg>
        </button>

        <span
          class="typo-p2 font-medium text-nowrap overflow-ellipsis overflow-hidden"
        >
          {{ selectedChatTitle() }}
        </span>
        <button sdIconButton variant="icon" color="neutral" size="md">
          <svg sdUseUIBasicIcon="more-horizontal"></svg>
        </button>
      </div>

      <!-- Chat -->
      <div
        class="h-full overflow-hidden"
        [class.hidden]="isChatsContainerOpened()"
      >
        <ng-content select="[chat]"></ng-content>
      </div>
      }

      <!-- Drawer -->
      <div
        class="fixed inset-0 mt-16 flex items-end justify-center transition-transform duration-500 ease-in-out overflow-hidden"
        [class.translate-y-full]="!isChatsContainerOpened()"
        [class.translate-y-0]="isChatsContainerOpened()"
      >
        <div
          class="w-full h-full shadow-lg rounded-t-3xl bg-shades-white flex flex-col pt-12 pb"
        >
          <ng-content select="[chats]"></ng-content>
        </div>
      </div>
    </div>
  `,
  styles: [``],
})
export class ChatsMobileComponent implements OnInit {
  private readonly store = inject<Store<fromChat.ChatsState>>(Store);

  public readonly selectedChatTitle = input.required<string | undefined>();
  public readonly isSideContainerOpened = this.store.selectSignal(
    fromChat.chatsFeature.selectIsSideContainerOpened
  );
  public readonly isChatsContainerOpened = this.store.selectSignal(
    fromChat.chatsFeature.selectIsChatsContainerOpened
  );
  public readonly newConvoClicked = output();

  ngOnInit(): void {
    this.store.dispatch(fromChat.ChatActions.openChatsContainer());
  }

  public toggleSideContainer() {
    if (this.isSideContainerOpened()) {
      this.store.dispatch(fromChat.ChatActions.closeSideContainer());
    } else {
      this.store.dispatch(fromChat.ChatActions.openSideContainer());
    }
  }

  public toggleChatsContainer() {
    if (this.isChatsContainerOpened()) {
      this.store.dispatch(fromChat.ChatActions.closeChatsContainer());
    } else {
      this.store.dispatch(fromChat.ChatActions.openChatsContainer());
    }
  }
}
