import { createFeature, createReducer, on } from '@ngrx/store';

import { LayoutActions } from './layout.actions';

export const layoutFeatureKey = 'layout';

export interface LayoutState {
  isMobile: boolean;
}

const initialLayoutState: LayoutState = {
  isMobile: false,
};

export const layoutFeature = createFeature({
  name: layoutFeatureKey,
  reducer: createReducer(
    initialLayoutState,
    on(LayoutActions.setIsMobile, (state, { isMobile }) => ({
      ...state,
      isMobile,
    }))
  ),
});
