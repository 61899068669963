import { createActionGroup, emptyProps, props } from '@ngrx/store';

import * as fromGenerated from '../../_generated';

export const ChatActions = createActionGroup({
  source: 'Chat',
  events: {
    'Select Chat': props<{
      id: number;
    }>(),
    'Load Empty Chat': emptyProps(),
    'Open Side Container': emptyProps(),
    'Close Side Container': emptyProps(),
    'Open Chats Container': emptyProps(),
    'Close Chats Container': emptyProps(),
  },
});

export const ChatsActions = createActionGroup({
  source: 'Chats',
  events: {
    'Add Chat': props<{ title?: string }>(),
    'Add Chat Success': props<{ chat: fromGenerated.ChatView }>(),
    'Add Chat Failure': props<{ error: string }>(),
    'Load Chats': emptyProps(),
    'Load Chats Success': props<{ chats: fromGenerated.ChatView[] }>(),
    'Load Chats Failure': props<{ error: string }>(),
  },
});

export const ChatWithMessagesActions = createActionGroup({
  source: 'ChatWithMessages',
  events: {
    'Load ChatWithMessages': props<{
      id: number;
    }>(),
    'Load ChatWithMessages Success': props<{
      chatWithMessages: fromGenerated.ChatWithMessagesView;
    }>(),
    'Load ChatWithMessages Failure': props<{ error: string }>(),
    'Add ChatMessage': props<{
      content: string;
    }>(),
    'Add ChatMessage Success': emptyProps(),
    'Add ChatMessage Failure': props<{ error: string }>(),
    'Received ChatWithMessages from Socket Success': props<{
      chatWithMessages: fromGenerated.ChatWithMessagesView;
    }>(),
  },
});

export const AssistantActions = createActionGroup({
  source: 'Assistant',
  events: {
    'Select Assistant': props<{
      assistant: 'DEFAULT' | 'COLLECTIVE_AGREEMENTS';
    }>(),
  },
});
