import { JsonPipe, NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, OnInit, computed, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import * as fromSeriousSystem from '@serious-system';
import { IconButtonDirective, UseUIBasicIconDirective } from '@serious-system';
import * as fromLayout from '../layout';
import { ChatComponent } from './chat.component';
import { NEW_CHAT_TITLE_DEFAULT } from './chats.constants';
import { ChatsDesktopComponent } from './chats.desktop.component';
import { ChatsMobileComponent } from './chats.mobile.component';
import {
  AssistantActions,
  ChatActions,
  ChatsActions,
} from './store/chats.actions';
import { ChatsState, chatsFeature } from './store/chats.reducer';

@Component({
  selector: 'squadbox-chats',
  standalone: true,
  imports: [
    TranslateModule,
    UseUIBasicIconDirective,
    IconButtonDirective,
    NgIf,
    NgClass,
    JsonPipe,
    FormsModule,
    NgTemplateOutlet,
    ChatsMobileComponent,
    ChatsDesktopComponent,
    fromSeriousSystem.ItemsNavigationComponent,
    fromSeriousSystem.ButtonDirective,
    ChatComponent,
  ],
  template: `
    <ng-container *ngIf="isMobile(); else desktop">
      <squadbox-chats-mobile
        [selectedChatTitle]="selectedChatTitle()"
        (newConvoClicked)="onNewConvoClicked()"
      >
        <sd-items-navigation
          chats
          [isMobile]="isMobile()"
          [items]="itemsNavigations()"
          (itemSelected)="onItemSelectedOnMobile($event)"
        ></sd-items-navigation>
        <squadbox-chat chat class="block w-full h-full"></squadbox-chat>
      </squadbox-chats-mobile>
    </ng-container>
    <ng-template #desktop>
      <squadbox-chats-desktop
        (newConvoClicked)="onNewConvoClicked()"
        [isNewChat]="isNewChat()"
        (assistantClicked)="onAssistantClicked($event)"
      >
        <div chats>
          <sd-items-navigation
            [items]="itemsNavigations()"
            (itemSelected)="onItemSelected($event)"
          ></sd-items-navigation>
        </div>
        <squadbox-chat chat class="w-full h-full flex flex-col"></squadbox-chat>
      </squadbox-chats-desktop>
    </ng-template>
  `,
  styles: [``],
})
export class ChatsComponent implements OnInit {
  private readonly translateService = inject(TranslateService);
  private readonly layoutStore = inject<Store<fromLayout.LayoutState>>(Store);
  private readonly chatsStore = inject<Store<ChatsState>>(Store);

  public readonly selectedChatTitle = computed(() => {
    const title = this.chatsStore.selectSignal(
      chatsFeature.selectSelectedChat
    )()?.title;

    if (!title || title === NEW_CHAT_TITLE_DEFAULT) {
      return this.translateService.instant(
        `NAVIGATION_ITEMS.${NEW_CHAT_TITLE_DEFAULT}`
      ) as string;
    }
    return title;
  });

  public readonly isMobile = this.layoutStore.selectSignal(
    fromLayout.layoutFeature.selectIsMobile
  );

  readonly isNewChat = computed(() => {
    const selectedChat = this.chatsStore.selectSignal(
      chatsFeature.selectSelectedChat
    );

    return selectedChat()?.messages?.length === 0;
  });

  public readonly itemsNavigations = computed(() => {
    const items = this.chatsStore.selectSignal(
      chatsFeature.selectChatsAsItemNavigation,
      {}
    );

    return items().map((item) => {
      if (!item.title || item.title === NEW_CHAT_TITLE_DEFAULT) {
        return {
          ...item,
          title: this.translateService.instant(
            `NAVIGATION_ITEMS.${NEW_CHAT_TITLE_DEFAULT}`
          ) as string,
        };
      }
      return item;
    });
  });

  ngOnInit(): void {
    this.chatsStore.dispatch(ChatsActions.loadChats());
  }

  public onItemSelected(item: fromSeriousSystem.ItemNavigation): void {
    this.chatsStore.dispatch(ChatActions.selectChat({ id: item.id }));
  }

  public onItemSelectedOnMobile(item: fromSeriousSystem.ItemNavigation): void {
    this.onItemSelected(item);
    this.chatsStore.dispatch(ChatActions.closeChatsContainer());
  }

  public onNewConvoClicked() {
    this.chatsStore.dispatch(ChatActions.loadEmptyChat());
  }

  public onAssistantClicked(assistant: 'DEFAULT' | 'COLLECTIVE_AGREEMENTS') {
    this.chatsStore.dispatch(AssistantActions.selectAssistant({ assistant }));
    this.chatsStore.dispatch(ChatActions.loadEmptyChat());
  }
}
