import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { ItemNavigation } from '@serious-system';
import { DateTime } from 'luxon';
import * as fromGenerated from '../../_generated';
import {
  AssistantActions,
  ChatActions,
  ChatsActions,
  ChatWithMessagesActions,
} from './chats.actions';

type ChatViewWithRequiredTitle = Omit<fromGenerated.ChatView, 'title'> &
  Required<Pick<fromGenerated.ChatView, 'title'>>;

const isChatViewWithRequiredTitle = (
  chat: fromGenerated.ChatView
): chat is ChatViewWithRequiredTitle => {
  return !!chat.title;
};

export const chatsFeatureKey = 'chats';

interface ChatWithMessages extends fromGenerated.ChatView {
  messages?: fromGenerated.ChatWithMessagesView['messages'];
}

export interface ChatsState extends EntityState<ChatWithMessages> {
  selectedChatId: number | null;
  error: string | null;
  isSideContainerOpened: boolean;
  isChatsContainerOpened: boolean;
  assistant: 'DEFAULT' | 'COLLECTIVE_AGREEMENTS';
}

const entityAdapter: EntityAdapter<ChatWithMessages> =
  createEntityAdapter<ChatWithMessages>({
    selectId: (view) => view.id,
    sortComparer: (a, b) => b.updatedAt.localeCompare(a.updatedAt),
  });

export const initialChatsState: ChatsState = entityAdapter.getInitialState({
  selectedChatId: null,
  error: null,
  isSideContainerOpened: false,
  isChatsContainerOpened: true,
  assistant: 'COLLECTIVE_AGREEMENTS',
});

export const chatsFeature = createFeature({
  name: chatsFeatureKey,
  reducer: createReducer(
    initialChatsState,
    on(ChatActions.selectChat, (state, { id }) => ({
      ...state,
      selectedChatId: id,
      error: null,
    })),
    on(ChatActions.loadEmptyChat, (state) => ({
      ...state,
      selectedChatId: null,
      error: null,
    })),
    on(ChatsActions.addChatSuccess, (state, { chat }) =>
      entityAdapter.addOne(chat, state)
    ),
    on(ChatsActions.loadChatsSuccess, (state, { chats }) => ({
      ...entityAdapter.setAll(chats, state),
    })),
    on(ChatsActions.loadChatsFailure, (state, { error }) => ({
      ...state,
      error,
    })),
    on(
      ChatWithMessagesActions.loadChatWithMessagesSuccess,
      (state, { chatWithMessages }) =>
        entityAdapter.upsertOne(chatWithMessages, state)
    ),
    on(
      ChatWithMessagesActions.receivedChatWithMessagesFromSocketSuccess,
      (state, { chatWithMessages }) =>
        entityAdapter.upsertOne(chatWithMessages, state)
    ),
    on(
      ChatWithMessagesActions.loadChatWithMessagesFailure,
      (state, { error }) => ({
        ...state,
        error,
      })
    ),
    on(ChatWithMessagesActions.addChatMessage, (state, { content }) => {
      const selectedChatId = state.selectedChatId;

      if (selectedChatId !== null) {
        const currentChat = state.entities[selectedChatId];

        if (currentChat) {
          return entityAdapter.updateOne(
            {
              id: currentChat.id,
              changes: {
                updatedAt: DateTime.local().toISO(),
                _count: {
                  ...currentChat._count,
                  messages: currentChat._count.messages + 1,
                },
                messages: [
                  ...(currentChat.messages ?? []),
                  {
                    id: DateTime.local().millisecond,
                    content,
                    isFromUser: true,
                    chatId: currentChat.id,
                    createdAt: DateTime.local().toISO(),
                    updatedAt: DateTime.local().toISO(),
                  },
                ],
              },
            },
            state
          );
        }
      }

      return state;
    }),
    on(ChatWithMessagesActions.addChatMessageFailure, (state, { error }) => ({
      ...state,
      error,
    })),
    on(ChatActions.openSideContainer, (state) => ({
      ...state,
      isSideContainerOpened: true,
    })),
    on(ChatActions.closeSideContainer, (state) => ({
      ...state,
      isSideContainerOpened: false,
    })),
    on(ChatActions.openChatsContainer, (state) => ({
      ...state,
      isChatsContainerOpened: true,
    })),
    on(ChatActions.closeChatsContainer, (state) => ({
      ...state,
      isChatsContainerOpened: false,
    })),
    on(AssistantActions.selectAssistant, (state, { assistant }) => ({
      ...state,
      assistant,
    }))
  ),
  extraSelectors: ({ selectChatsState, selectSelectedChatId }) => {
    const entitySelectors = entityAdapter.getSelectors(selectChatsState);

    return {
      ...entitySelectors,
      selectChatsAsItemNavigation: createSelector(
        selectSelectedChatId,
        entitySelectors.selectAll,
        (selectedChatId, chats) =>
          /**
           * We are filtering out chats without a title because we don't want to
           * show them in the navigation. Note that we should only have 1 chat
           * per user that doesn't have a title, which is the "Home Chat" up until
           * a message has been added to it.
           */
          chats
            .filter((chat) => chat._count.messages)
            .filter(isChatViewWithRequiredTitle)
            .sort(
              (a, b) =>
                DateTime.fromISO(b.updatedAt).toMillis() -
                DateTime.fromISO(a.updatedAt).toMillis()
            )
            .map<ItemNavigation>(
              (chat) =>
                ({
                  id: chat.id,
                  title: chat.title,
                  selected: chat.id === selectedChatId,
                  date: DateTime.fromISO(chat.updatedAt).toLocal(),
                } satisfies ItemNavigation)
            )
      ),
      selectSelectedChat: createSelector(
        selectSelectedChatId,
        entitySelectors.selectEntities,
        (selectedChatId, entities) =>
          selectedChatId ? entities[selectedChatId] : null
      ),
    };
  },
});
