/* eslint-disable sonarjs/no-duplicate-string */
import {
  animate,
  keyframes,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { NgIf } from '@angular/common';
import { Component, inject, input, output } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IconButtonDirective, UseUIBasicIconDirective } from '@serious-system';
import * as fromChat from './index';
import * as fromChats from './index';

@Component({
  selector: 'squadbox-chats-desktop',
  standalone: true,
  imports: [
    UseUIBasicIconDirective,
    IconButtonDirective,
    NgIf,
    TranslateModule,
  ],
  template: `
    <div
      class="grid grid-flow-col grid-cols-[min-content_auto] h-full overflow-hidden"
    >
      <div
        class="absolute left-20 top-4 flex flex-col gap-4 *:shadow-sm transition-all duration-300 *:shadow-xs z-50"
        [class.opacity-0]="isChatsContainerOpened()"
        [class.opacity-100]="!isChatsContainerOpened()"
      >
        <div
          sdIconButton
          variant="outlined"
          color="neutral"
          size="md"
          (click)="toggleChatsContainer()"
        >
          <svg sdUseUIBasicIcon="sidebar"></svg>
        </div>
        <button
          sdIconButton
          variant="outlined"
          color="primary"
          size="md"
          [@moveIcon]="isChatsContainerOpened() ? 'open' : 'closed'"
          (click)="newConvoClicked.emit()"
        >
          <svg sdUseUIBasicIcon="new-convo"></svg>
        </button>

        <button
          sdIconButton
          variant="outlined"
          color="primary"
          size="md"
          [@moveCCIcon]="isChatsContainerOpened() ? 'open' : 'closed'"
          (click)="assistantClicked.emit('COLLECTIVE_AGREEMENTS')"
        >
          <img src="assets/illustrations/convention-outline.svg" />
        </button>
      </div>

      <div
        class="bg-shades-white border-r border-neutral-200 overflow-y-auto transition-all duration-300"
        [class.w-0]="!isChatsContainerOpened()"
        [class.w-70]="isChatsContainerOpened()"
        [class.desktop-xl:w-110]="isChatsContainerOpened()"
      >
        <div class="flex h-14 justify-between items-center mt-2 px-4">
          <button
            sdIconButton
            variant="icon"
            color="neutral"
            size="sm"
            (click)="toggleChatsContainer()"
          >
            <svg sdUseUIBasicIcon="sidebar"></svg>
          </button>
          <button
            sdIconButton
            variant="icon"
            color="primary"
            size="sm"
            (click)="newConvoClicked.emit()"
          >
            <svg sdUseUIBasicIcon="new-convo"></svg>
          </button>
        </div>

        <div class="flex flex-col px-4 pt-6">
          <div
            class="flex items-center justify-start gap-3 tablet-landscape:typo-p3 typo-p2 w-full tablet-landscape:h-10 h-14 px-2 py-2 rounded cursor-pointer hover:bg-primary-500/5"
            (click)="assistantClicked.emit('COLLECTIVE_AGREEMENTS')"
          >
            <button
              sdIconButton
              variant="outlined"
              color="primary"
              size="sm"
              class="size-5"
              [class.opacity-0]="!isChatsContainerOpened()"
              [class.opacity-100]="isChatsContainerOpened()"
            >
              <img src="assets/illustrations/convention-outline.svg" />
            </button>

            <span
              class="typo-p3 font-semibold max-w-full text-nowrap text-ellipsis overflow-x-hidden py-2"
              >Collective Agreements</span
            >
          </div>
        </div>

        <ng-content select="[chats]"></ng-content>
      </div>
      <div class="h-full overflow-hidden">
        <ng-content select="[chat]"></ng-content>
      </div>
    </div>
  `,
  styles: [``],
  animations: [
    trigger('moveIcon', [
      state('open', style({ transform: 'translate(0, 0)' })),
      state('closed', style({ transform: 'translate(0, 0)' })),
      transition('open => closed', [
        animate(
          '0.3s',
          keyframes([
            style({ transform: 'translate(11rem, -4rem)', offset: 0 }),
            style({ transform: 'translate(9.9rem, -3.6rem)', offset: 0.1 }),
            style({ transform: 'translate(8.8rem, -3.2rem)', offset: 0.2 }),
            style({ transform: 'translate(7.7rem, -2.8rem)', offset: 0.3 }),
            style({ transform: 'translate(6.6rem, -2.4rem)', offset: 0.4 }),
            style({ transform: 'translate(5.5rem, -2rem)', offset: 0.5 }),
            style({ transform: 'translate(4.4rem, -1.6rem)', offset: 0.6 }),
            style({ transform: 'translate(3.3rem, -1.2rem)', offset: 0.7 }),
            style({ transform: 'translate(2.2rem, -0.8rem)', offset: 0.8 }),
            style({ transform: 'translate(1.1rem, -0.4rem)', offset: 0.9 }),
            style({ transform: 'translate(0, 0)', offset: 1 }),
          ])
        ),
      ]),
    ]),
    trigger('moveCCIcon', [
      state('open', style({ transform: 'translate(0, -4rem)' })),
      state('closed', style({ transform: 'translate(0, 0)' })),
      transition('open => closed', [
        animate(
          '0.3s',
          keyframes([
            style({ transform: 'translate(0, -4rem)', offset: 0 }),
            style({ transform: 'translate(0, 0)', offset: 1 }),
          ])
        ),
      ]),
    ]),

    trigger('moveCC2Icon', [
      state('open', style({ transform: 'translate(0, -4rem)' })),
      state('closed', style({ transform: 'translate(0, 0)' })),
      transition('open => closed', [
        animate(
          '0.3s',
          keyframes([
            style({ transform: 'translate(0, -4rem)', offset: 0 }),
            style({ transform: 'translate(0, 0)', offset: 1 }),
          ])
        ),
      ]),
    ]),
  ],
})
export class ChatsDesktopComponent {
  readonly isNewChat = input<boolean>(false);
  public readonly newConvoClicked = output();
  public readonly assistantClicked = output<
    'DEFAULT' | 'COLLECTIVE_AGREEMENTS'
  >();

  private readonly translateService = inject(TranslateService);
  private readonly chatsStore = inject<Store<fromChats.ChatsState>>(Store);

  readonly assistant = toSignal(
    this.chatsStore.select(fromChats.chatsFeature.selectAssistant)
  );

  private readonly store = inject<Store<fromChat.ChatsState>>(Store);

  public readonly isChatsContainerOpened = this.store.selectSignal(
    fromChat.chatsFeature.selectIsChatsContainerOpened
  );

  public toggleChatsContainer() {
    if (this.isChatsContainerOpened()) {
      this.store.dispatch(fromChat.ChatActions.closeChatsContainer());
    } else {
      this.store.dispatch(fromChat.ChatActions.openChatsContainer());
    }
  }
}
