import { createActionGroup, props } from '@ngrx/store';

export const LayoutActions = createActionGroup({
  source: 'Layout',
  events: {
    'Set Is Mobile': props<{
      isMobile: boolean;
    }>(),
  },
});
