/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { JsonPipe, NgClass, NgIf } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  ViewChild,
  computed,
  effect,
  inject,
  signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import * as fromSeriousSystem from '@serious-system';
import { PromptInputComponent } from '@serious-system';
import * as fromAuth from '../auth';
import { NEW_CHAT_TITLE_DEFAULT } from './chats.constants';
import * as fromChats from './index';

@Component({
  standalone: true,
  selector: 'squadbox-chat',
  imports: [
    JsonPipe,
    fromSeriousSystem.ButtonDirective,
    fromSeriousSystem.IconButtonDirective,
    fromSeriousSystem.UseArrowIconDirective,
    fromSeriousSystem.MessageComponent,
    fromSeriousSystem.UseLogoDirective,
    fromSeriousSystem.UseAiIconDirective,
    fromSeriousSystem.UseIllustrationDirective,
    NgClass,
    NgIf,
    PromptInputComponent,
    TranslateModule,
  ],
  template: `
    <div class="relative h-full overflow-hidden">
      <div class="absolute top-0 w-full flex items-start ">
        <div
          class="h-full w-full pt-2 pb-4 flex justify-center bg-gradient-t-horizontal "
        >
          @if (assistant() !== 'DEFAULT' && selectedChat()?.messages?.length) {

          <div
            class="bg-shades-white rounded-full border border-neutral-200 px-4 py-2.5 font-semibold typo-p3 shadow-md"
          >
            <span class="">{{
              'ASSISTANTS.' + assistant() + '.TITLE' | translate
            }}</span>
          </div>
          }
        </div>
      </div>

      <div chat class="grid grid-rows-[1fr_auto] h-full w-full">
        <div #messageContainer class="flex-grow overflow-y-auto">
          <div
            class="text-h5 flex tablet-landscape:pt-14 justify-center gap-2 w-full h-full"
            [ngClass]="{
            'items-center': !selectedChat()?.messages?.length,
          }"
          >
            <!-- PROMPTS AND WELCOME MESSAGE -->
            @if (!selectedChat()?.messages?.length) {
            <div class="flex flex-col items-center justify-center gap-8 h-full">
              <div
                class="flex flex-col items-center justify-center gap-8 max-tablet-landscape:mt-auto"
              >
                <img class="h-14" sdUseLogo="logo-minimal-filled" />

                <div class="flex flex-col items-center text-center">
                  <div class="flex flex-row gap-3 items-center">
                    <span class="typo-h6 font-semibold leading-8">{{
                      'ASSISTANTS.' + assistant() + '.TITLE'
                        | translate : { userName: userName() }
                    }}</span>
                    <img
                      *ngIf="assistant() === 'DEFAULT'"
                      sdUseIllustration="bubble"
                      class="size-6"
                    />
                  </div>
                  <span
                    class="typo-p3 text-neutral-500 leading-5 text-center"
                    >{{
                      'ASSISTANTS.' + assistant() + '.WELCOME_MESSAGE'
                        | translate
                    }}</span
                  >
                </div>
              </div>

              <div
                class="
                flex flex-row gap-4
                p-4 max-tablet-landscape:mt-auto
                w-screen tablet-landscape:max-w-150
                max-tablet-landscape:overflow-scroll scrollbar-none"
              >
                <!-- PROMPT -->
                @for (prompt of prompts(); track prompt.title) {
                <div
                  class="
                  flex flex-row tablet-landscape:flex-col gap-4 tablet-landscape:gap-2
                  bg-primary-500/10 hover:bg-primary-500/15
                  rounded-3xl
                  p-4 w-full
                  max-tablet-landscape:min-w-70
                  cursor-pointer
                "
                  (click)="addChatMessage(prompt.prompt)"
                >
                  <svg
                    [sdUseAiIcon]="prompt.icon"
                    class="text-primary-500 size-5 max-tablet-landscape:min-w-5 max-tablet-landscape:self-center"
                  ></svg>
                  <div class="typo-p2 tablet-landscape:typo-p3">
                    {{ prompt.title }}
                  </div>
                </div>
                }
              </div>
            </div>
            } @else {

            <!-- MESSAGES -->
            <div
              class="
              flex flex-col gap-4
              w-full p-4 tablet-landscape:max-w-150
            "
            >
              @for (message of selectedChat()?.messages; track message.id; let i
              = $index) {
              <sd-message
                [isFromUser]="message.isFromUser"
                [message]="message.content"
                class="last:tablet-landscape:pb-10 last:pb-4"
                [ngClass]="{
                'self-end': message.isFromUser,
                'self-start': !message.isFromUser,
              }"
              />
              <!-- AI IS THINKING 🧠 -->
              } @if (isThinking()) {
              <sd-message
                [isFromUser]="false"
                [isThinking]="true"
                class="self-start tablet-landscape:pb-10 pb-4"
              />
              }
            </div>
            }
          </div>
        </div>

        <div class="sticky bottom-0 w-full bg-shades-white z-10">
          <div
            class="flex flex-col justify-center w-full h-auto px-4 py-2 tablet-portrait:px-6 tablet-portrait:py-4 tablet-landscape:pt-0 tablet-landscape:pb-4 tablet-landscape:px-4 tablet-landscape:items-center"
          >
            <!-- SCROLL BUTTON -->
            @if (showScrollButton) {
            <button
              sdIconButton
              variant="outlined"
              color="neutral"
              size="xs"
              class="absolute -top-10 left-1/2 transform -translate-x-1/2 z-20 bg-shades-white"
              (click)="scrollToBottom('smooth')"
            >
              <svg sdUseArrowIcon="arrow-down"></svg>
            </button>
            }
            <!-- INPUT PROMPT -->
            <sd-prompt-input
              [disabled]="isThinking()"
              (promptSubmitted)="addChatMessage($event)"
            ></sd-prompt-input>
          </div>
          <!-- DISCLAIMER -->
          <div
            class="pb-4 typo-p3 text-neutral-500 hidden tablet-landscape:block text-center"
          >
            {{ 'CHAT.AI_ASSISTANTS_CAN_MAKE_MISTAKES' | translate }}
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      ::-webkit-scrollbar {
        display: none;
      }
    `,
  ],
})
export class ChatComponent implements AfterViewInit {
  private readonly translateService = inject(TranslateService);
  private readonly chatsStore = inject<Store<fromChats.ChatsState>>(Store);
  private readonly authStore = inject(Store);

  private readonly user = toSignal(
    this.authStore.select(fromAuth.authFeature.selectUser)
  );

  readonly assistant = toSignal(
    this.chatsStore.select(fromChats.chatsFeature.selectAssistant)
  );

  selectedChatMessagesLength = 0;
  isThinking = signal(false);
  showScrollButton = false;

  @ViewChild('messageContainer') messageContainer!: ElementRef;

  prompts = computed(() => {
    const promptsArray = [];

    for (let i = 1; i < 5; i++) {
      promptsArray.push({
        icon: this.translateService.instant(
          `ASSISTANTS.${this.assistant()}.PRE_PROMPTS.${i}.icon`
        ),
        title: this.translateService.instant(
          `ASSISTANTS.${this.assistant()}.PRE_PROMPTS.${i}.title`
        ),
        prompt: this.translateService.instant(
          `ASSISTANTS.${this.assistant()}.PRE_PROMPTS.${i}.prompt`
        ),
      });
    }
    return promptsArray;
  });

  // prompts = signal<{ title: string; prompt: string }[]>([
  // {
  //   title: 'Contract Detective: Uncover Hidden Clauses',
  //   prompt:
  //     'Can you review this contract for me? I’ll upload or copy the text, and I’d like you to focus on specific points or answer some questions I have about it.',
  // },
  // {
  //   title: 'Document Genie: Create Legal Document',
  //   prompt:
  //     'I need help creating a legal document. Can I provide you with the details so you can help me draft it?',
  // },
  // {
  //   title: 'Risk Radar: Spot Legal Hazards',
  //   prompt:
  //     'Can you assess the legal risks for a situation or project I’m working on? I’ll describe it, and I need you to help me identify the potential hazards.',
  // },
  // {
  //   title: 'Litigation Playbook: Plan Your Next Move',
  //   prompt:
  //     'I need to prepare for litigation. Could you help me by going over the case with me and assisting in identifying key arguments or organizing evidence?',
  // },
  // ]);

  // ------------------------------------
  // LIFECYCLE
  // ------------------------------------
  constructor() {
    effect(
      () => {
        const messagesLength = this.selectedChat()?.messages?.length ?? 0;

        if (this.hasMessagesLengthChanged(messagesLength)) {
          this.handleNewMessages(messagesLength);
        }

        this.updateScrollButtonVisibility();
      },
      { allowSignalWrites: true }
    );
  }

  ngAfterViewInit() {
    this.scrollToBottom();
    this.messageContainer.nativeElement.addEventListener('scroll', () => {
      this.showScrollButton =
        this.messageContainer.nativeElement.scrollTop <
        this.messageContainer.nativeElement.scrollHeight -
          this.messageContainer.nativeElement.clientHeight;
    });
  }

  // ------------------------------------
  //  COMPUTED
  // ------------------------------------
  public readonly selectedChat = computed(() => {
    const selectedChat = this.chatsStore.selectSignal(
      fromChats.chatsFeature.selectSelectedChat
    );

    if (
      !selectedChat()?.title ||
      selectedChat()?.title === NEW_CHAT_TITLE_DEFAULT
    ) {
      return {
        ...selectedChat(),
        title: this.translateService.instant(
          `NAVIGATION_ITEMS.${NEW_CHAT_TITLE_DEFAULT}`
        ) as string,
      };
    }

    return selectedChat();
  });

  public readonly userName = computed(() => {
    return `${this.user()?.firstName}`;
  });

  scrollToBottom(behavior: 'smooth' | 'auto' = 'auto'): void {
    try {
      this.messageContainer.nativeElement.scrollTo({
        top: this.messageContainer.nativeElement.scrollHeight,
        behavior,
      });
    } catch (err) {
      console.error('Could not scroll to bottom:', err);
    }
  }

  // ------------------------------------
  // FUNCTIONS
  // ------------------------------------
  public addChatMessage(content: string): void {
    this.isThinking.set(true);

    this.chatsStore.dispatch(
      fromChats.ChatWithMessagesActions.addChatMessage({
        content,
      })
    );
  }

  private hasMessagesLengthChanged(messagesLength: number): boolean {
    return messagesLength !== this.selectedChatMessagesLength;
  }

  private handleNewMessages(messagesLength: number): void {
    const isLastMessageFromChat =
      !this.selectedChat()?.messages?.[messagesLength - 1]?.isFromUser;

    if (isLastMessageFromChat) {
      this.isThinking.set(false);
    }

    this.selectedChatMessagesLength = messagesLength;

    setTimeout(() => {
      this.scrollToBottom();
      this.updateScrollButtonVisibility();
    }, 0);
  }

  private updateScrollButtonVisibility(): void {
    this.showScrollButton =
      this.messageContainer.nativeElement.scrollTop <
      this.messageContainer.nativeElement.scrollHeight -
        this.messageContainer.nativeElement.clientHeight;
  }
}
