import { inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  filter,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import * as fromGenerated from '../../_generated';
import { ChatsSocket } from '../chats.socket';
import {
  ChatActions,
  ChatsActions,
  ChatWithMessagesActions,
} from './chats.actions';
import { chatsFeature, ChatsState } from './chats.reducer';

@Injectable()
export class ChatsEffects {
  private readonly actions$ = inject(Actions);
  private readonly chatsService = inject(fromGenerated.ChatsService);
  private readonly store = inject(Store<ChatsState>);
  private readonly chatsSocket = inject(ChatsSocket);

  constructor() {
    this.chatsSocket
      .fromEvent<{
        type: typeof ChatsSocket.ChatMessageAnswered;
        chatWithMessages: fromGenerated.ChatWithMessagesView;
      }>(ChatsSocket.ChatMessageAnswered)
      .pipe(
        takeUntilDestroyed(),
        tap(({ chatWithMessages }) => {
          this.store.dispatch(
            ChatWithMessagesActions.receivedChatWithMessagesFromSocketSuccess({
              chatWithMessages,
            })
          );
        })
      )
      .subscribe();
    this.chatsSocket
      .fromEvent<Error>(ChatsSocket.Error)
      .pipe(
        takeUntilDestroyed(),
        tap((error) => {
          console.error(`${error.name}: ${error.message}`);
        })
      )
      .subscribe();
  }

  loadChats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatsActions.loadChats),
      exhaustMap(() =>
        this.chatsService.chatsControllerGetChats().pipe(
          map((chats) => ChatsActions.loadChatsSuccess({ chats })),
          catchError((error: Error) =>
            of(ChatsActions.loadChatsFailure({ error: error.message }))
          )
        )
      )
    )
  );

  loadChatsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatsActions.loadChatsSuccess),
      withLatestFrom(this.store.select(chatsFeature.selectSelectedChatId)),
      map(([{ chats }, selectedChatId]) =>
        ChatActions.selectChat({
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          id: selectedChatId ?? chats.find((chat) => !chat._count.messages)!.id,
        })
      )
    )
  );

  addChatSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatsActions.addChatSuccess),
      map(({ chat }) => ChatActions.selectChat({ id: chat.id }))
    )
  );

  selectChat$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.selectChat),
      map(({ id }) => ChatWithMessagesActions.loadChatWithMessages({ id }))
    )
  );

  loadEmptyChat$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.loadEmptyChat),
      map(() => ChatsActions.loadChats())
    )
  );

  loadChatWithMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatWithMessagesActions.loadChatWithMessages),
      switchMap(({ id }) =>
        this.chatsService.chatsControllerGetChatWithMessages(id).pipe(
          map((chatWithMessages) =>
            ChatWithMessagesActions.loadChatWithMessagesSuccess({
              chatWithMessages,
            })
          ),
          catchError((error: Error) =>
            of(
              ChatWithMessagesActions.loadChatWithMessagesFailure({
                error: error.message,
              })
            )
          )
        )
      )
    )
  );

  addChatMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatWithMessagesActions.addChatMessage),
      withLatestFrom(this.store.select(chatsFeature.selectSelectedChatId)),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      filter(([_, selectedChatId]) => !!selectedChatId),
      switchMap(([{ content }, selectedChatId]) =>
        this.chatsService
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          .chatsControllerAddChatMessage(selectedChatId!, { content })
          .pipe(
            map(() => ChatWithMessagesActions.addChatMessageSuccess()),
            catchError((error: Error) =>
              of(
                ChatWithMessagesActions.addChatMessageFailure({
                  error: error.message,
                })
              )
            )
          )
      )
    )
  );
}
