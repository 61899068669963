import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, timer } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import * as fromGenerated from '../../_generated';

import { Store } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import { AuthActions } from './auth.actions';
import { AuthState } from './auth.reducer';

@Injectable()
export class AuthEffects {
  private readonly actions$ = inject(Actions);
  private readonly userService = inject(fromGenerated.UsersService);
  private readonly authService = inject(fromGenerated.AuthService);
  private readonly store = inject(Store<AuthState>);

  login$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.login),
        switchMap(
          () =>
            (window.location.href = `${environment.apiBaseUrl}/v1/auth/login`)
        )
      ),
    { dispatch: false }
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.logout),
        switchMap(
          () =>
            (window.location.href = `${environment.apiBaseUrl}/v1/auth/logout`)
        )
      ),
    { dispatch: false }
  );

  loadUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loadUser),
      switchMap(() =>
        this.userService.usersControllerGetMe().pipe(
          map((user) => AuthActions.loadUserSuccess({ user: user })),
          catchError((error: Error) =>
            of(AuthActions.loadUserFailure({ error: error.message }))
          )
        )
      )
    )
  );

  loadUserSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loadUserSuccess),
      map(() => AuthActions.startRefreshTokensTimer())
    )
  );

  startRefreshTokensInterval$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.startRefreshTokensTimer),
        switchMap(() =>
          timer(environment.refreshTokensTimerDue).pipe(
            tap(() => this.store.dispatch(AuthActions.refreshTokens()))
          )
        )
      ),
    { dispatch: false }
  );

  refreshTokens$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.refreshTokens),
      switchMap(() =>
        this.authService.authControllerRefreshTokens().pipe(
          map(() => {
            return AuthActions.refreshTokensSuccess();
          }),
          catchError((error: Error) =>
            of(AuthActions.refreshTokensFailure({ error: error.message }))
          )
        )
      )
    )
  );

  refreshTokensSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.refreshTokensSuccess),
      map(() => AuthActions.startRefreshTokensTimer())
    )
  );
}
